import request from '@/utils/request'


// 查询用户房产认证列表
export function listHouseAuth(query) {
  return request({
    url: '/user/user-house-auth/list',
    method: 'get',
    params: query
  })
}

// 查询用户房产认证分页
export function pageHouseAuth(query) {
  return request({
    url: '/user/user-house-auth/page',
    method: 'get',
    params: query
  })
}

// 查询用户房产认证详细
export function getHouseAuth(data) {
  return request({
    url: '/user/user-house-auth/detail',
    method: 'get',
    params: data
  })
}

// 新增用户房产认证
export function addHouseAuth(data) {
  return request({
    url: '/user/user-house-auth/add',
    method: 'post',
    data: data
  })
}

// 修改用户房产认证
export function updateHouseAuth(data) {
  return request({
    url: '/user/user-house-auth/edit',
    method: 'post',
    data: data
  })
}

// 删除用户房产认证
export function delHouseAuth(data) {
  return request({
    url: '/user/user-house-auth/delete',
    method: 'post',
    data: data
  })
}
